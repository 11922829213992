<template>
  <div
    class="personal-table-row"
    :class="{ active, 'no-padding': noPadding }"
    @click="$emit('click')"
  >
    <div v-if="name" class="left">
      <div class="name" :class="{ colorSecondary: nameGreyColor }" v-html="name" />
      <div v-if="active" class="active-text" v-html="getTranslation('active')" />
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';

@Component
export default class PersonalTableRow extends SequoiaComponent {
  @Prop()
  name!: string;

  @Prop()
  nameGreyColor?: boolean;

  @Prop()
  active!: boolean;

  @Prop()
  noPadding!: boolean;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.personal-table-row {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid var(--alpha-light-4);
  @include body1;

  &.no-padding {
    padding: 0;
  }

  &:last-child {
    border-bottom: 0;
  }

  &.active {
    padding-right: 16px;
    padding-left: 16px;
    margin-right: -16px;
    margin-left: -16px;
    background-color: var(--c-overlay-brand-custom-1);
  }

  .active-text {
    color: var(--c-light-800);
  }

  .left {
    display: flex;
    align-items: center;
  }

  .name {
    display: inline-block;
    max-width: 300px;
    margin-right: 16px;
    @extend %truncate-after-1-line;
  }
}
</style>
