<template>
  <div class="profile-sms-auth">
    <div class="input-holder">
      <InputText
        ref="smscode"
        v-model="smsCode"
        input-category="floating"
        size="medium"
        :maxlength="4"
        type="number"
        :label="getTranslation('code_from_sms')"
        :status="error.verification ? 'error' : undefined"
        :message="error.verification"
      />

      <ButtonDefault view="secondary" :disabled="sendSmsSeconds > 0" size="medium" @click="sendSms">
        <span v-html="getTranslation('resend_code')" />
      </ButtonDefault>
    </div>
    <p class="body2 color-light-error" v-html="error.common" />
    <p
      class="body2 color-light-secondary"
      :style="{ visibility: sendSmsSeconds > 0 && !error.common ? 'visible' : 'hidden' }"
    >
      <span v-html="getTranslation('send_code_again_in')" />:&#32;
      <span v-html="sendSmsSeconds" />&#32; <span v-html="getTranslation('sec')" />.
    </p>
    <LoaderSpinner v-if="verification.loading" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import * as api from 'src/api';
import { REGISTRATION_SMS_CODE_LENGTH, RESEND_SMS_CODE_TIMEOUT } from 'src/constants';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import { Ref, Vue, Watch } from 'vue-property-decorator';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import { cleanupPhone } from 'src/utils/phone';
import { wlDetector } from 'src/utils';

@Component({
  components: {
    ButtonDefault,
    InputText,
    LoaderSpinner,
  },
})
export default class ProfileSmsAuth extends SequoiaComponent {
  smsCode = '';
  error = {
    common: '',
    verification: '',
  };
  verification: { loading: boolean; result: null | { [key: string]: any } } = {
    loading: false,
    result: null,
  };
  sendSmsSeconds = RESEND_SMS_CODE_TIMEOUT;
  sendSmsInterval: NodeJS.Timeout | null = null;

  @Ref('smscode')
  refSmscode?: Vue;

  @Watch('smsCode')
  async onCheckCode(val: string) {
    this.error.common = '';

    if (
      val.length === REGISTRATION_SMS_CODE_LENGTH &&
      !this.verification.loading &&
      !this.verification.result
    ) {
      await this.verifySmsCode();
    }
  }

  get isKt() {
    return wlDetector().isKt;
  }

  mounted() {
    this.sendSms();

    (this.refSmscode?.$refs.input as HTMLInputElement | undefined)?.focus();
  }

  beforeDestroy() {
    if (this.sendSmsInterval) {
      clearInterval(this.sendSmsInterval);
    }
  }

  async sendSms() {
    try {
      this.sendSmsSeconds = RESEND_SMS_CODE_TIMEOUT;
      await api.auth.contactsVerification.startWithAccountSession({
        data: {
          type: 'phone',
          contacts: cleanupPhone(
            this.$store.account?.user?.phone,
            this.$store.authAndReg.usePlusInPhoneMethods.registration
          ),
          reason: 'default',
        },
      });
      this.sendSmsInterval = setInterval(() => {
        if (this.sendSmsSeconds <= 0) {
          if (this.sendSmsInterval) {
            clearInterval(this.sendSmsInterval);
          }
          return;
        }
        this.sendSmsSeconds--;
      }, 1000);
    } catch (err) {
      this.error.common = err.message || 'Произошла ошибка. Пожалуйста, попробуйте еще раз позднее';
    }
  }

  async verifySmsCode() {
    this.verification.loading = true;
    try {
      this.verification.result = await api.auth.contactsVerification.verify({
        data: {
          type: 'phone',
          contacts: cleanupPhone(
            this.$store.account?.user?.phone,
            this.$store.authAndReg.usePlusInPhoneMethods.registration
          ),
          code: this.smsCode,
        },
      });
      await this.authorizeWithSessionToken();
      this.error.verification = '';
    } catch (err) {
      this.error.verification = err.message;
    } finally {
      this.verification.loading = false;
    }
  }

  async authorizeWithSessionToken() {
    try {
      const config = {
        params: {
          'contacts-session-token': this.verification.result?.sessionToken,
        },
        data: {
          login: this.$store.account?.user.login,
        },
      };
      if (this.isKt) {
        await api.profile.managing(config);
      } else {
        await api.profile.managingWithVerifiedContacts(config);
      }
      this.$emit('authorized');
    } catch (err) {
      this.error.common = err?.data?.message;
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-sms-auth {
  .input-holder {
    display: flex;
    margin-bottom: 20px;
  }

  .input-block {
    width: 130px;
    margin-right: 20px;
  }
}
</style>
