var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"profile-forms"},[_c('h3',{staticClass:"mb-32",domProps:{"innerHTML":_vm._s(_vm.getTranslation('subscriptions_managing'))}}),_c('h5',{domProps:{"innerHTML":_vm._s(_vm.getTranslation('subscriptions'))}}),_c('ul',{staticClass:"subs"},_vm._l((_vm.offers),function(offer){return _c('li',{key:offer.id,staticClass:"sub flex-center-vertical hidden-link-container"},[_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(offer.renderingData.title)}}),(!_vm.isYakassaSubscriptionActive(offer.id))?_c('span',{staticClass:"subscriptions-autopay-none body2 color-error"},[(_vm.isYakassaSubscriptionInRetry(offer.id))?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getTranslation('subscription_in_retry_error'))}}):(
              (offer.autoRenewInfo && !offer.autoRenewInfo.isAutoRenewEnabled) || offer.yakassa
            )?_c('span',{staticClass:"mb-8",domProps:{"innerHTML":_vm._s(
              _vm.getTranslation('autopay_active_till') +
              (offer.yakassa
                ? _vm.getYakassaSubscriptionExpiresAt(offer.id)
                : _vm.getCurrentPeriodExpiresAt(offer))
            )}}):_vm._e()]):_vm._e()]),(
          (offer.autoRenewInfo && offer.autoRenewInfo.isAutoRenewEnabled) ||
          (offer.yakassa &&
            (_vm.isYakassaSubscriptionActive(offer.id) || _vm.isYakassaSubscriptionInRetry(offer.id)))
        )?_c('button',{staticClass:"link nav-colored hidden-link",attrs:{"type":"button"},domProps:{"innerHTML":_vm._s(_vm.getTranslation('managing'))},on:{"click":function($event){return _vm.changeSubscription(offer)}}}):(
          (offer.autoRenewInfo && offer.autoRenewInfo.canResumeAutoRenew) || offer.yakassa
        )?_c('button',{staticClass:"link action-colored",attrs:{"type":"button"},on:{"click":function($event){return _vm.enableAutoRenew(offer)}}},[_vm._v("\n        "+_vm._s(_vm.getTranslation('renew'))+"\n      ")]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }