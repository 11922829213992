<template>
  <button
    type="button"
    class="link link-icon nav-colored button-add-profile"
    :class="theme"
    @click="$emit('click')"
  >
    <IconSVG :svg="IconPlusCircled" />
    <span v-html="getTranslation('add_profile')" />
  </button>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';
import ProfileEditDelete from 'src/components/personal/profiles-list/parts/ProfileEditDelete.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlusCircled from 'src/svg/plus-circled.svg';

@Component({
  components: {
    ButtonAction,
    ProfileEditDelete,
    IconSVG,
  },
})
export default class ProfileAddButton extends SequoiaComponent {
  IconPlusCircled = IconPlusCircled;

  @Prop()
  hideProfileEdit!: boolean;

  @Prop()
  disableDelete!: boolean;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.button-add-profile {
  position: relative;
  width: 100%;
  padding: 24px 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -16px;
    z-index: var(--z-0);
    width: calc(100% + 32px);
    height: 100%;
    padding: 0 16px;
    border-radius: 4px;
  }

  &:hover::before {
    background-color: var(--alpha-light-3);
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------

  &.light {
    color: var(--c-light-link-base);

    @include devices-with-hover {
      &:hover {
        color: var(--c-light-link-hover);

        &::before {
          background-color: var(--alpha-light-3);
        }
      }
    }

    &:active,
    &.active {
      color: var(--c-light-link-active);

      &::before {
        background-color: var(--alpha-light-4);
      }
    }
  }

  &.dark {
    color: var(--c-dark-link-base);

    @include devices-with-hover {
      &:hover {
        color: var(--c-dark-link-hover);

        &::before {
          background-color: var(--alpha-dark-3);
        }
      }
    }

    &:active,
    &.active {
      color: var(--c-dark-link-active);

      &::before {
        background-color: var(--alpha-dark-4);
      }
    }
  }
}
</style>
