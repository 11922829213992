import { render, staticRenderFns } from "./ProfileManaging.vue?vue&type=template&id=975890ee&"
import script from "./ProfileManaging.vue?vue&type=script&lang=ts&"
export * from "./ProfileManaging.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports