<template>
  <div
    class="personal-table mb-48"
    :class="[$store.theme, { 'bg-dark-200': $store.theme === 'dark' }]"
    :data-cy="dataCy"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';

@Component
export default class PersonalTable extends SequoiaComponent {
  @Prop()
  dataCy!: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.personal-table {
  flex-grow: 1;
  padding: 16px 32px;
  border-radius: 8px;

  @include mobile {
    padding-right: 24px;
    padding-left: 24px;
  }

  &.light {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }

  .dark {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  }
}
</style>
