<template>
  <div class="profile-edit-delete" :class="{ 'with-text': withText }">
    <ButtonAction
      :theme="theme"
      :icon="IconEdit"
      :text="withText ? getTranslation('edit') : ''"
      @click="$emit('edit')"
    />
    <ButtonAction
      :theme="theme"
      :icon="IconDelete"
      :text="withText ? getTranslation('delete') : ''"
      :disabled="disableDelete"
      @click="$emit('delete')"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';
import IconEdit from 'src/svg/edit.svg';
import IconDelete from 'src/svg/delete.svg';

@Component({
  components: { ButtonAction },
})
export default class ProfileEditDelete extends SequoiaComponent {
  IconEdit = IconEdit;
  IconDelete = IconDelete;

  @Prop()
  themeForced?: 'light' | 'dark';

  @Prop()
  disableDelete?: boolean;

  @Prop()
  withText?: boolean;

  get theme() {
    return this.themeForced || this.$store.theme;
  }
}
</script>

<style lang="scss" scoped>
.profile-edit-delete {
  display: flex;

  &.with-text {
    flex-direction: column;
  }
}
</style>
