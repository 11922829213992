var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"subscriptions-block v-2 block block-bg dark",style:(_vm.bgImage)},[_c('h4',{staticClass:"mb-24",domProps:{"innerHTML":_vm._s(_vm.titleTranslation)}}),(
      (_vm.autoRenewInfo && !_vm.isAutoRenewEnabled) ||
      (_vm.yakassaSubscription && !_vm.isYakassaSubscriptionActive)
    )?_c('div',{staticClass:"subscriptions-autopay mb-24"},[_c('div',{staticClass:"color-error"},[(_vm.isYakassaSubscriptionInRetry)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getTranslation('subscription_in_retry_error'))}}):[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getTranslation('autopay_canceled_till'))}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.offer.yakassa ? _vm.yakassaSubscriptionExpiresAt : _vm.currentPeriodExpiresAt)}})]],2),(_vm.isYakassaSubscriptionInRetry)?_c('PaymentRetryActions',{attrs:{"subscription-option-id":_vm.subscriptionId},on:{"loading":_vm.onLoadingChange,"success":_vm.onSuccess}}):_vm._e(),(
        (_vm.yakassaSubscription && !_vm.isYakassaSubscriptionInRetry) ||
        (_vm.autoRenewInfo && !_vm.isAutoRenewEnabled && _vm.canResumeAutoRenew)
      )?_c('ButtonDefault',{domProps:{"innerHTML":_vm._s(_vm.getTranslation('autopay_renew'))},on:{"click":_vm.enableAutoRenew}}):_vm._e(),(_vm.isLoading)?_c('LoaderSpinner'):_vm._e()],1):_vm._e(),_c('ul',{staticClass:"subscriptions-info mb-16"},[(_vm.priceAndPeriodTranslation)?_c('li',{domProps:{"innerHTML":_vm._s(_vm.priceAndPeriodTranslation)}}):_vm._e(),(_vm.offer.channels)?_c('li',{domProps:{"innerHTML":_vm._s(_vm.channelsCountTranslation)}}):_vm._e(),(_vm.yakassaSubscription)?_c('li',{domProps:{"innerHTML":_vm._s(`${_vm.getTranslation(_vm.nextPaymentText)} ${_vm.yakassaSubscriptionExpiresAt}`)}}):(_vm.currentPeriodExpiresAt && _vm.isAutoRenewEnabled)?_c('li',{domProps:{"innerHTML":_vm._s(`${_vm.getTranslation(_vm.nextPaymentText)} ${_vm.currentPeriodExpiresAt}`)}}):_vm._e()]),(_vm.channels.length)?_c('transition-group',{staticClass:"subscriptions-channels flex",attrs:{"name":"fade-ease-out-slow"}},_vm._l((_vm.channels),function(channel){return _c('img',{key:channel.id,attrs:{"src":_vm.getChannelImageUrl(channel),"alt":""}})}),0):_vm._e(),(_vm.allChannels.length > _vm.channels.length && !_vm.showAllChannels)?_c('button',{staticClass:"link action-colored",attrs:{"type":"button"},domProps:{"innerHTML":_vm._s(_vm.getTranslation('show_all_channels'))},on:{"click":_vm.onShowAllChannels}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }