<template>
  <form class="profile-forms" @submit.prevent="">
    <h3 class="mb-32" v-html="getTranslation('payment_managing')" />
    <h5 v-html="getTranslation('payment_method')" />

    <div class="block-row hidden-link-container">
      <div v-if="paymentCard" class="card flex-center-vertical">
        <img class="card-img" :src="paymentCardTypeImage" :alt="paymentCardType" />
        *<span v-text="paymentCardLast4" />
      </div>
      <a
        class="link nav-colored hidden-link"
        @click.prevent="managePaymentMethod()"
        v-html="getTranslation('managing')"
      />
    </div>
  </form>
</template>

<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import Component from 'vue-class-component';

@Component
export default class PaymentManaging extends SequoiaComponent {
  get paymentCard() {
    return selectors.payment.cardSelector(this.$store);
  }

  get paymentCardType() {
    return selectors.payment.cardTypeSelector(this.$store);
  }

  get paymentCardTypeImage() {
    return selectors.payment.cardTypeImageSelector(this.$store);
  }

  get paymentCardLast4() {
    return selectors.payment.cardLast4Selector(this.$store);
  }

  managePaymentMethod() {
    this.$emit('showPaymentModal');
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/blocks';
</style>

<style lang="scss" scoped>
.block-row.hidden-link-container {
  padding-bottom: 0;
}

h5 {
  margin-bottom: 0;
}
</style>
