<template>
  <label class="input-checkbox" :class="[$store.theme, { disabled }]">
    <span class="text">
      <slot />
    </span>
    <input v-model="inputVal" type="checkbox" :disabled="disabled" />
    <span class="checkbox">
      <IconSVG :svg="IconCheck" :size="20" />
    </span>
  </label>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import IconCheck from 'src/svg/check.svg';
import IconSVG from 'src/components/IconSVG.vue';

@Component({
  components: { IconSVG },
})
export default class InputCheckbox extends Vue {
  IconCheck = IconCheck;

  @Prop({ default: false })
  value!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  get inputVal() {
    return this.value;
  }

  set inputVal(val) {
    this.$emit('input', val);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/typography';

.input-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 1.875rem;
  margin-bottom: 8px;
  cursor: pointer;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* stylelint-ensable property-no-vendor-prefix */
  @include body2;

  &.disabled {
    pointer-events: none;
  }

  input:checked ~ .checkbox,
  &:hover input:checked ~ .checkbox {
    .icon {
      display: inline-flex;
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;

    &:checked {
      &:disabled {
        pointer-events: none;

        ~ .checkbox::after {
          opacity: 0.7;
        }
      }

      ~ .checkbox::after {
        display: block;
      }
    }
  }

  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 2px;
    border-radius: 4px;
    transition: all 0.15s var(--ease-out);

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      display: none;
      transition: all 0.15s var(--ease-out);
      transform: translate(-50%, -50%);
    }
  }

  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  &.light {
    &.disabled {
      color: var(--c-light-font-tertiary);
    }

    &:hover input:not(:disabled) ~ .checkbox {
      background-color: var(--alpha-light-3);
    }

    &:hover input:checked:not(:disabled) ~ .checkbox {
      background-color: var(--c-light-brand-lighter);
      border-color: var(--c-light-brand-lighter);
    }

    input:checked ~ .checkbox {
      background-color: var(--c-light-brand);
      border-color: var(--c-light-brand);
    }

    input:disabled ~ .checkbox {
      background-color: var(--alpha-light-3);
      border-color: var(--alpha-light-3);
    }

    .checkbox {
      border-color: var(--alpha-light-5);
    }
  }

  &.dark {
    &.disabled {
      color: var(--c-dark-font-tertiary);
    }

    &:hover input:not(:disabled) ~ .checkbox {
      background-color: var(--alpha-dark-3);
    }

    &:hover input:checked:not(:disabled) ~ .checkbox {
      background-color: var(--c-dark-brand-lighter);
      border-color: var(--c-dark-brand-lighter);
    }

    input:checked ~ .checkbox {
      background-color: var(--c-dark-brand);
      border-color: var(--c-dark-brand);
    }

    input:disabled ~ .checkbox {
      background-color: var(--alpha-dark-3);
      border-color: var(--alpha-dark-3);
    }

    .checkbox {
      border-color: var(--alpha-dark-5);
    }
  }
}
</style>
