<template>
  <form class="subscriptions-prolonging-v2 profile-forms" @submit.prevent="">
    <h3 class="mb-24 mt-16" v-html="currentOffer.renderingData.title" />
    <div class="profile-forms-description" v-html="autopayCancelWarning" />

    <div class="buttons-wrapper fluid">
      <ButtonDefault @click="closeModal" v-html="getTranslation('continue_watching')" />
      <ButtonDefault
        type="button"
        view="secondary"
        @click="disableAutoRenew"
        v-html="getTranslation('disconnect')"
      />
    </div>
  </form>
</template>

<script lang="ts">
import { actions } from 'src/store/actions';
import * as api from 'src/api';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconAngleLeft from 'src/svg/angle-left.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TOfferEnhancedV2 } from 'src/api/offers/types';
import logger from 'src/utils/logger';
import { DateTime } from 'src/utils/time/date-time';
import { subscriptionsSelector } from 'src/store/payment/selectors';
import { THRESHOLDS } from 'src/constants';

const log = logger('personal-subscription-prolonging');

@Component({ components: { ButtonDefault, IconSVG } })
export default class SubscriptionProlongingV2 extends SequoiaComponent {
  IconAngleLeft = IconAngleLeft;

  @Prop()
  currentOffer!: TOfferEnhancedV2;

  get autopayCancelWarning() {
    const warning = this.getTranslation('autopay_cancel_warning');
    return warning.replace(`%expire%`, this.expireDate || '');
  }

  get autopayCancelledText() {
    const cancelled = this.getTranslation('autopay_cancelled');
    return cancelled.replace(`%subscribe%`, `${this.currentOffer.renderingData.title}` || '');
  }

  get yakassaSubscriptions() {
    return subscriptionsSelector(this.$store);
  }

  get yakassaSubscription() {
    if (this.currentOffer.yakassa) {
      return this.yakassaSubscriptions.find(
        (sub) => sub.id === this.currentOffer.yakassa?.subscriptionId
      );
    }

    return null;
  }
  get expireDate() {
    const { dateUTCMultiplier } = THRESHOLDS.subscribes;
    const expiresAt = this.currentOffer.yakassa
      ? (this.yakassaSubscription?.expires_at || 0) * dateUTCMultiplier
      : Date.parse(this.currentOffer.autoRenewInfo?.currentPeriodExpiresAt || '');
    const date = new Date(expiresAt);
    const month = DateTime.getDMonth(date);
    const year = date.getFullYear();
    return `${month}.${year}`;
  }

  async disableAutoRenew() {
    if (this.currentOffer.yakassa) {
      try {
        await api.profile.cancelSubscription(this.yakassaSubscription?.id || '');
        await actions.payment.loadPaymentSubscriptions(this.$store);
        actions.common.showProfileNotification(this.$store, this.autopayCancelledText);
      } catch (err) {
        this.$emit('showNotificationError', this.getTranslation('subscription_renew_in_progress'));
      }
    } else {
      try {
        await api.profile.disableAutoRenewSubscription(this.currentOffer.id);
        await actions.payment.loadPaymentSubscriptionsV2(this.$store);
        actions.common.showProfileNotification(this.$store, this.disableAutoRenewText);
        await this.$emit('getOffers');
      } catch (err) {
        log.error(err);
      }
    }
    this.closeModal();
    this.gaEvent({
      category: 'personal_area',
      action: 'Клик по "Отключить" в модальном окне "Управление подписками"',
      offer_id: this.currentOffer.id,
    });
  }

  get disableAutoRenewText() {
    return this.getTranslation('disable_auto_renew').replace(
      `%subscription%`,
      this.currentOffer.renderingData.title || ''
    );
  }

  closeModal() {
    actions.common.hideModal(this.$store);
  }
}
</script>
