<template>
  <div class="profile-actions">
    <ButtonAction :icon="IconToProfile" :disabled="disableSelect" @click="$emit('select')" />

    <template v-if="!hideProfileEdit">
      <div class="hidden-mobile">
        <ProfileEditDelete
          :disable-delete="disableDelete"
          @edit="$emit('edit')"
          @delete="$emit('delete')"
        />
      </div>

      <div class="visible-mobile">
        <ButtonAction :icon="IconMoreVertical" @click="showOverlayOnMobile = true" />
        <transition-group name="fade-ease-out-fast" mode="out-in">
          <template v-if="showOverlayOnMobile">
            <button
              key="button"
              type="button"
              class="overlay-bg"
              @click="showOverlayOnMobile = false"
            />
            <ProfileEditDelete
              key="profileeditdelete"
              class="context-menu"
              :disable-delete="disableDelete"
              :with-text="true"
              @edit="$emit('edit')"
              @delete="$emit('delete')"
            />
          </template>
        </transition-group>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';
import ProfileEditDelete from 'src/components/personal/profiles-list/parts/ProfileEditDelete.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconMoreVertical from 'src/svg/more-vertical.svg';
import IconToProfile from 'src/svg/to-profile.svg';

@Component({
  components: {
    ButtonAction,
    ProfileEditDelete,
    IconSVG,
  },
})
export default class ProfileActions extends SequoiaComponent {
  showOverlayOnMobile = false;
  IconToProfile = IconToProfile;
  IconMoreVertical = IconMoreVertical;

  @Prop()
  hideProfileEdit!: boolean;

  @Prop()
  disableSelect!: boolean;

  @Prop()
  disableDelete!: boolean;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.profile-actions {
  display: flex;
  align-items: center;

  .overlay-bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-2);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--c-overlay-lvl-60);
  }

  .context-menu {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-3);
    background-color: var(--c-light-150);
    border-radius: 4px 4px 0 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  }
}
</style>
