<template>
  <div>
    <h3 class="mb-24" v-html="getTranslation('personal_data')" />

    <PersonalTable v-if="$store.account" data-cy="personal-data">
      <PersonalTableRow :name="getTranslation('login')" :name-grey-color="true">
        <span class="value" v-text="userLogin" />
      </PersonalTableRow>
      <PersonalTableRow v-if="settings.showEmail" name="E-mail" :name-grey-color="true">
        <span class="value" v-text="userEmail" />
      </PersonalTableRow>
      <PersonalTableRow :name="getTranslation('provider')" :name-grey-color="true">
        <span class="value" v-text="providerName" />
      </PersonalTableRow>
    </PersonalTable>

    <ButtonDefault
      v-if="settings.showChangePasswordButton"
      view="secondary"
      data-cy="change-password"
      @click="changePasswordModal()"
    >
      <span v-html="getTranslation('change_password')" />
    </ButtonDefault>

    <ButtonSignOut />

    <div v-if="$store.payment.method && $store.payment.method.saved" class="subscriptions-managing">
      <a
        class="link action-tertiary"
        @click.prevent="managePaymentMethod()"
        v-html="getTranslation('payment_managing')"
      />
    </div>

    <div v-if="contentBlock" class="content-block" v-html="contentBlock" />

    <ModalSequoia
      v-if="isModalOpen"
      type="fixed"
      size="small"
      :with-button-back="paymentModal"
      @back="handleModalStepBack"
    >
      <PaymentManaging
        v-if="paymentManagingModal"
        class="with-side-and-bottom-padding"
        @showPaymentModal="showPaymentChangeModal"
      />
      <PaymentChange v-else-if="paymentModal" class="with-side-and-bottom-padding" />
      <ChangePassword
        v-else-if="changingPassword"
        class="with-side-and-bottom-padding"
        @close="changingPassword = false"
      />
    </ModalSequoia>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import ChangePassword from 'src/components/personal/forms/ChangePassword.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import PaymentManaging from 'src/components/personal/forms/PaymentManaging.vue';
import PaymentChange from 'src/components/personal/forms/PaymentChange.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import NotificationSimple from 'src/components/ui/notifications/NotificationSimple.vue';
import IconSVG from 'src/components/IconSVG.vue';
import logger from 'src/utils/logger';
import PersonalTable from 'src/components/personal/PersonalTable.vue';
import PersonalTableRow from 'src/components/personal/PersonalTableRow.vue';
import ButtonSignOut from 'src/components/personal/ButtonSignOut.vue';

const log = logger('personal-data');

@Component({
  components: {
    ButtonSignOut,
    PersonalTableRow,
    PersonalTable,
    ChangePassword,
    PaymentManaging,
    PaymentChange,
    ModalSequoia,
    NotificationSimple,
    ButtonDefault,
    IconSVG,
  },
})
export default class PersonalDataPage extends SequoiaComponent {
  paymentModal = false;
  paymentManagingModal = false;
  changingPassword = false;

  get settings() {
    return selectors.personal.personalSettingsSelector(this.$store);
  }

  get providerName() {
    return selectors.account.providerNameSelector(this.$store);
  }

  get userEmail() {
    return selectors.account.userEmailSelector(this.$store);
  }

  get userLogin() {
    return selectors.account.userLoginSelector(this.$store);
  }

  get contentBlock() {
    return this.getByLanguage(this.settings?.personalDataContentBlock);
  }

  get isModalOpen() {
    return (
      selectors.common.isModalOpenSelector(this.$store) &&
      !this.showWelcomeScreen &&
      (this.paymentManagingModal || this.paymentModal || this.changingPassword)
    );
  }

  async mounted() {
    try {
      await actions.payment.loadPaymentMethod(this.$store, true);
    } catch (err) {
      log.error('onLoadPaymentMethod', err);
    }
  }

  managePaymentMethod() {
    this.paymentManagingModal = true;
    actions.common.showModal(this.$store);
    this.gaEvent({ category: 'personal_area', action: 'Клик по "Управление платежами"' });
  }

  showPaymentChangeModal() {
    this.paymentManagingModal = false;
    this.paymentModal = true;
    this.gaEvent({
      category: 'personal_area',
      action: 'Клик по "Управление" в модальном окне "Управление платежами"',
    });
  }

  handleModalStepBack() {
    this.paymentModal = false;
    this.paymentManagingModal = true;
  }

  changePasswordModal() {
    this.paymentModal = false;
    this.paymentManagingModal = false;
    this.changingPassword = true;
    actions.common.showModal(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.button-sign-out {
  margin-top: 48px;

  @include mobile-and-tablet {
    margin-top: 32px;
  }
}
</style>
