<template>
  <div class="subscriptions-block v-2 block block-bg dark" :style="bgImage">
    <h4 class="mb-24" v-html="titleTranslation" />

    <div
      v-if="
        (autoRenewInfo && !isAutoRenewEnabled) ||
        (yakassaSubscription && !isYakassaSubscriptionActive)
      "
      class="subscriptions-autopay mb-24"
    >
      <div class="color-error">
        <span
          v-if="isYakassaSubscriptionInRetry"
          v-html="getTranslation('subscription_in_retry_error')"
        />
        <template v-else>
          <span v-html="getTranslation('autopay_canceled_till')" />&#32;<span
            v-html="offer.yakassa ? yakassaSubscriptionExpiresAt : currentPeriodExpiresAt"
          />
        </template>
      </div>
      <PaymentRetryActions
        v-if="isYakassaSubscriptionInRetry"
        :subscription-option-id="subscriptionId"
        @loading="onLoadingChange"
        @success="onSuccess"
      />
      <ButtonDefault
        v-if="
          (yakassaSubscription && !isYakassaSubscriptionInRetry) ||
          (autoRenewInfo && !isAutoRenewEnabled && canResumeAutoRenew)
        "
        @click="enableAutoRenew"
        v-html="getTranslation('autopay_renew')"
      />
      <LoaderSpinner v-if="isLoading" />
    </div>

    <ul class="subscriptions-info mb-16">
      <li v-if="priceAndPeriodTranslation" v-html="priceAndPeriodTranslation" />
      <li v-if="offer.channels" v-html="channelsCountTranslation" />
      <li
        v-if="yakassaSubscription"
        v-html="`${getTranslation(nextPaymentText)} ${yakassaSubscriptionExpiresAt}`"
      />
      <li
        v-else-if="currentPeriodExpiresAt && isAutoRenewEnabled"
        v-html="`${getTranslation(nextPaymentText)} ${currentPeriodExpiresAt}`"
      />
    </ul>

    <transition-group
      v-if="channels.length"
      name="fade-ease-out-slow"
      class="subscriptions-channels flex"
    >
      <img
        v-for="channel in channels"
        :key="channel.id"
        :src="getChannelImageUrl(channel)"
        alt=""
      />
    </transition-group>
    <button
      v-if="allChannels.length > channels.length && !showAllChannels"
      type="button"
      class="link action-colored"
      @click="onShowAllChannels"
      v-html="getTranslation('show_all_channels')"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import * as api from 'src/api';
import { imageServerUrl } from 'src/utils/images';
import logger from 'src/utils/logger';
import {
  DESKTOP_WINDOW_WIDTH,
  legacyRenderingMethods,
  BILLING_RENDERING_METHOD_NAME,
  FALLBACK_RENDERING_METHOD_NAME,
  TABLET_WINDOW_WIDTH,
  SUBSCRIPTION_STATUSES,
} from 'src/constants';
import { actions } from 'src/store/actions';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import PaymentRetryActions from 'src/components/payment-retry/parts/PaymentRetryActions.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import { getChannelLogo } from 'src/utils/channel';
import { TOfferEnhancedV2 } from 'src/api/offers/types';
import cloneDeep from 'lodash/cloneDeep';
import { sortByPriority } from 'src/utils/object';
import { expireDate } from 'src/utils/time/date';
import { availableTemplates } from 'src/utils/quick-subscribe';
import { TChannelEnhanced } from 'src/api/channels/types';
import { subscriptionsSelector } from 'src/store/payment/selectors';

const log = logger('personal-subscription-block');

enum INITIAL_CHANNELS_LENGTH {
  desktop = 27,
  tablet = 24,
  mobile = 12,
}

@Component({
  components: { LoaderSpinner, PaymentRetryActions, ButtonDefault },
})
export default class SubscriptionBlockV2 extends SequoiaComponent {
  isLoading = false;
  showAllChannels = false;

  @Prop({ required: true })
  offer!: TOfferEnhancedV2;

  get bgImage() {
    return {
      backgroundImage: `linear-gradient(to bottom, rgba(13, 13, 13, 0.8), rgba(13, 13, 13, 0.9) 60%, #0d0d0d), url(${
        this.getByLanguage(this.$store.siteConfig?.wlSpecials?.QSTvBackground)?.url
      })`,
      backgroundSize: 'cover',
    };
  }
  get autoRenewInfo() {
    return this.offer.autoRenewInfo;
  }

  get isAutoRenewEnabled() {
    return this.autoRenewInfo?.isAutoRenewEnabled;
  }

  get canResumeAutoRenew() {
    return this.autoRenewInfo?.canResumeAutoRenew;
  }

  get priceAndPeriodTranslation() {
    return this.renderingMethodConfig?.priceAndPeriod?.translation.trim();
  }

  get channelsCountTranslation() {
    return this.getTranslation('showcase_channels_count').replace(
      '%channelsCount%',
      this.offer.mainOffer?.showcaseChannelsCount.toString() || '0'
    );
  }

  get currentPeriodExpiresAt() {
    return expireDate(Date.parse(this.offer.autoRenewInfo?.currentPeriodExpiresAt || '') / 1000);
  }

  get renderingMethods() {
    if (this.offer.mainOffer?.renderingMethods?.length) {
      return sortByPriority(
        (this.offer.mainOffer?.renderingMethods).filter(
          (method) =>
            availableTemplates[method.name] || method.name === BILLING_RENDERING_METHOD_NAME
        )
      );
    }

    return [];
  }

  get renderingMethodConfig() {
    const renderingMethodName = this.renderingMethods[0]?.name;
    if (renderingMethodName) {
      const isLegacyMethod = legacyRenderingMethods.includes(renderingMethodName);
      const isBillingMethod = renderingMethodName === BILLING_RENDERING_METHOD_NAME;

      const renderingMethodConfig = cloneDeep(
        this.$store.QSConfig[
          isLegacyMethod || isBillingMethod ? FALLBACK_RENDERING_METHOD_NAME : renderingMethodName
        ]
      );

      this.prepareRenderingMethodConfig(renderingMethodConfig);
      return renderingMethodConfig;
    }
    return null;
  }

  get yakassaSubscriptions() {
    return subscriptionsSelector(this.$store);
  }

  get yakassaSubscription() {
    if (this.offer.yakassa) {
      return this.yakassaSubscriptions.find((sub) => sub.id === this.offer.yakassa?.subscriptionId);
    }

    return null;
  }
  get yakassaSubscriptionStatus() {
    return this.yakassaSubscription?.subscription_renew_status || '';
  }

  get isYakassaSubscriptionActive() {
    return this.yakassaSubscriptionStatus === SUBSCRIPTION_STATUSES.ACTIVE;
  }

  get isYakassaSubscriptionInRetry() {
    return this.yakassaSubscriptionStatus === SUBSCRIPTION_STATUSES.IN_RETRY;
  }

  get yakassaSubscriptionExpiresAt() {
    return expireDate(this.yakassaSubscription?.expires_at);
  }

  get subscriptionId() {
    return this.offer.yakassa?.subscriptionId || '';
  }

  get titleTranslation() {
    return this.renderingMethodConfig?.title?.translation || '{title}';
  }

  get allChannels() {
    if (!(this.$store.tvChannels?.list && this.offer?.channels)) {
      return [];
    }

    const uniqueChannels = Array.from(new Set(this.offer.channels));

    return uniqueChannels.reduce((accumulator: TChannelEnhanced[], uniqueChannel) => {
      const channel = this.$store.tvChannels.list[uniqueChannel.channelId];
      if (channel) {
        accumulator.push(channel);
      }
      return accumulator;
    }, []);
  }

  get channels() {
    return this.allChannels.slice(0, this.channelsLength);
  }

  get channelsLength() {
    if (this.showAllChannels) {
      return this.allChannels.length;
    }

    let deviceType: keyof typeof INITIAL_CHANNELS_LENGTH = 'mobile';
    if (window.innerWidth >= DESKTOP_WINDOW_WIDTH) {
      deviceType = 'desktop';
    } else if (window.innerWidth >= TABLET_WINDOW_WIDTH) {
      deviceType = 'tablet';
    }

    return INITIAL_CHANNELS_LENGTH[deviceType];
  }

  get nextPaymentText() {
    return this.offer.temporaryOffer ? 'next_payment_trial' : 'next_payment';
  }

  async mounted() {
    try {
      await actions.payment.loadPaymentMethod(this.$store);
    } catch (err) {
      log.error(err);
    }
  }

  prepareRenderingMethodConfig(renderingMethodConfig: { [x: string]: any }) {
    Object.keys(renderingMethodConfig).forEach((propertyName) => {
      const property = renderingMethodConfig[propertyName];
      const propertyType =
        property.translationId || property.translationId === '' ? 'translation' : 'contentBlock';

      property[propertyType] =
        (this.getByLanguage(property[propertyType]) || '').replace(
          '%channelCount%',
          `${this.offer.mainOffer?.showcaseChannelsCount || 0}`
        ) || '';
      property.variables.forEach((variable: string | number) => {
        const variableFromRenderingData = this.offer.renderingData[variable] || '';

        property[propertyType] = property[propertyType].replace(
          `@${variable}@`,
          variableFromRenderingData
        );
      });
    });
  }

  onLoadingChange(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  onShowAllChannels() {
    this.showAllChannels = true;
  }

  getChannelImageUrl(channel: any) {
    return imageServerUrl(getChannelLogo(channel), 168, 96);
  }

  async enableAutoRenew() {
    if (this.offer.yakassa) {
      try {
        await api.profile.activateSubscription(this.offer.id);
        await this.onSuccess();
        actions.common.showProfileNotification(
          this.$store,
          this.getTranslation('subscription_renewed').replace(
            `%offerTitle%`,
            this.offer.renderingData.title || ''
          )
        );
      } catch (err) {
        log.error(err);
      }
    } else {
      try {
        await api.profile.enableAutoRenewSubscription(this.offer.id || '');
        await actions.payment.loadPaymentSubscriptionsV2(this.$store);
        await this.$emit('getOffers');
        actions.common.showProfileNotification(
          this.$store,
          this.getTranslation('enable_auto_renew').replace(
            `%subscription%`,
            this.offer.renderingData.title || ''
          )
        );
      } catch (err) {
        log.error(err);
      }
    }
  }

  async onSuccess() {
    await actions.payment.loadPaymentSubscriptions(this.$store);
    await this.$emit('getOffers');
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.subscriptions-block {
  margin-bottom: 16px;
  border-radius: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  .link {
    margin-top: 16px;
  }

  .subscriptions-info {
    @include body2;

    li {
      position: relative;
      padding-left: 12px;
      margin: 4px 0;

      &::before {
        content: '';
        position: absolute;
        top: 40%;
        left: 0;
        width: 4px;
        height: 4px;
        background-color: var(--c-light-brand);
        border-radius: 50%;
      }
    }
  }

  .subscriptions-channels {
    img {
      width: 5.25rem;
      height: 3rem;
      margin-right: 8px;
      margin-bottom: 8px;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }

  .subscriptions-autopay {
    line-height: 1.25rem;

    a {
      text-decoration: none;
      border-bottom-style: dashed;
      border-bottom-width: 1px;

      @include devices-with-hover {
        &:hover {
          border-bottom-style: solid;
        }
      }
    }

    .color-error {
      margin-bottom: 12px;
    }

    @include mobile-and-tablet {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        margin-top: 16px;
      }
    }
  }
}
</style>
