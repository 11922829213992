<template>
  <form @submit.prevent="handleManagingLogin">
    <InputText
      v-model="managingLoginForm.password"
      input-category="floating"
      size="medium"
      icon="visibility"
      :tab-index-for-button="-1"
      :status="errors.login ? 'error' : undefined"
      :label="getTranslation('password')"
      :message="errors.login"
    />

    <div class="buttons-wrapper">
      <ButtonDefault>
        <span v-html="getTranslation('save')" />
      </ButtonDefault>
      <ButtonDefault view="secondary" type="button" @click="closeModal">
        <span v-html="getTranslation('cancel')" />
      </ButtonDefault>
    </div>
  </form>
</template>
<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import * as api from 'src/api';
import { actions } from 'src/store/actions';
import logger from 'src/utils/logger';

const log = logger('profile');

@Component({
  components: { InputText, ButtonDefault },
})
export default class ProfileManaging extends SequoiaComponent {
  errors = {
    login: '',
  };
  managingLoginForm = {
    password: '',
  };
  async handleManagingLogin() {
    try {
      await api.profile.managing({
        data: {
          email: this.$store.account?.user.login,
          password: this.managingLoginForm.password,
        },
      });
      this.$emit('authorized');
    } catch (err) {
      this.errors.login = err?.data?.message;
      log.error(err);
    }
  }

  async closeModal() {
    actions.common.hideModal(this.$store);
  }
}
</script>
