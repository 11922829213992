<template>
  <div class="profile-forms">
    <div v-if="managing && withSmsConfirmation && smsMode">
      <h3 v-html="getTranslation('edit_profile')" />
      <div class="profile-forms-description" v-html="getTranslation('sms_sent')" />

      <ProfileSmsAuth class="mb-16" @authorized="onAuthConfirmed" />

      <a
        v-if="showToggleMode"
        class="link action-colored"
        @click="toggleMode"
        v-html="getTranslation('enter_password')"
      />
    </div>

    <div v-else-if="managing">
      <h3 v-html="getTranslation('edit_profile')" />
      <div class="profile-forms-description" v-html="getTranslation('confirm_text')" />

      <ProfileManaging @authorized="onAuthConfirmed" />
    </div>

    <form v-else class="edit-form" @submit.prevent="">
      <h3 v-if="actionName === PROFILE_ACTIONS.EDIT" v-html="getTranslation('edit_profile')" />
      <h3 v-else v-html="getTranslation('add_profile')" />

      <InputText
        v-model.trim="profileName"
        input-category="floating"
        size="medium"
        :label="getTranslation('name')"
        :maxlength="15"
        :minlength="2"
      />

      <DropdownSequoia
        v-slot="{ value, opened, toggle }"
        :list="profileRestrictionsOptions"
        :transform-native-select="true"
        class="select-restrictions"
        @select="onChangeRestriction"
      >
        <ButtonDropdown
          width="auto"
          :placeholder="getTranslation('profile_type')"
          :open="opened"
          :value="value"
          :floating="true"
          @click="toggle"
        />
      </DropdownSequoia>

      <InputCheckbox :value="isPinRequired" @input="checkPin">
        <span v-html="getTranslation('pin_text')" />
      </InputCheckbox>

      <div v-if="isPinRequired" class="password">
        <InputText
          v-model="pin"
          input-category="floating"
          size="medium"
          icon="visibility"
          :tab-index-for-button="-1"
          :maxlength="4"
          :allow-digits-only="true"
          :label="getTranslation('enter_pin')"
          :description="getTranslation('enter_4_characters')"
        />
      </div>

      <div class="buttons-wrapper">
        <ButtonDefault
          :disabled="isSubmitPinButtonDisabled"
          @click="actionName === PROFILE_ACTIONS.ADD ? addProfile() : editProfile()"
        >
          <span v-html="getTranslation('save')" />
        </ButtonDefault>

        <ButtonDefault view="secondary" type="button" @click="closeModal">
          <span v-html="getTranslation('cancel')" />
        </ButtonDefault>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import * as api from 'src/api';
import { HTTP_CODES, PROFILE_ACTIONS, PROFILE_PIN_LENGTH } from 'src/constants';
import DropdownSequoia from 'src/components/ui/dropdown/DropdownSequoia.vue';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';
import InputCheckbox from 'src/components/ui/input/InputCheckbox.vue';
import ProfileSmsAuth from 'src/components/personal/forms/ProfileSmsAuth.vue';
import ProfileManaging from 'src/components/personal/forms/ProfileManaging.vue';
import { TProfile } from 'src/api/auth/types';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import { wlDetector } from 'src/utils';
import logger from 'src/utils/logger';

const log = logger('profile');

@Component({
  components: {
    ProfileSmsAuth,
    ButtonDefault,
    InputText,
    InputCheckbox,
    DropdownSequoia,
    ButtonDropdown,
    ProfileManaging,
  },
})
export default class ProfileEdit extends SequoiaComponent {
  @Prop()
  profile!: Partial<TProfile>;

  @Prop()
  actionName!: string;

  deferredAction = '';
  data: { Name: any; Pin: string; 'Restriction.Id': any } | null = null;
  pin = '';
  restrictionId? = '1';
  isPinRequired? = false;
  id = '';
  PROFILE_ACTIONS = PROFILE_ACTIONS;
  managing = false;
  smsMode = true;
  changedName?: string | null = null;

  mounted() {
    this.isPinRequired = this.profile.pin_required;
    this.restrictionId = this.profile.restriction;
  }

  get withSmsConfirmation() {
    return wlDetector().isSmotreshka || wlDetector().isKt || wlDetector().isIntersv;
  }

  get showToggleMode() {
    return !wlDetector().isKt;
  }

  get profileRestrictionsOptions() {
    return this.$store.profileRestrictions.map((item) => ({
      ...item,
      selected: item.id === this.profile.restriction,
      key: item.value,
      value: item.name,
    }));
  }

  get isSubmitPinButtonDisabled() {
    if (this.isPinRequired) {
      return this.pin?.length !== PROFILE_PIN_LENGTH || !this.profileName;
    } else {
      return !this.profileName;
    }
  }

  get profileCreatedText() {
    const profileCreatedTranslation = this.getTranslation('profile_created');
    const profileCreated = profileCreatedTranslation.replace(
      '%profile%',
      `<span>«${this.changedName}»</span>`
    );
    return `<span>${profileCreated}</span>`;
  }

  get profileEditedText() {
    const profileEditedTranslation = this.getTranslation('profile_edited');
    const profileEdited = profileEditedTranslation.replace(
      '%profile%',
      `<span>«${this.profileName}»</span>`
    );
    return `<span>${profileEdited}</span>`;
  }

  get profileName() {
    if (this.changedName === null) {
      return this.profile.name;
    } else {
      return this.changedName;
    }
  }

  set profileName(ev) {
    this.changedName = ev;
  }

  checkPin(event: boolean) {
    this.isPinRequired = event;
  }

  onChangeRestriction(item: TDropdownItem) {
    this.restrictionId = item.id;
    this.isPinRequired = item.id === 'adult' || item.id === '4';
  }

  async onAuthConfirmed() {
    try {
      if (this.deferredAction === 'add') {
        await this.addProfile();
      } else if (this.deferredAction === 'edit') {
        await this.editProfile();
      }
    } catch (err) {
      this.handleError(err);
    }
  }

  async editProfile() {
    this.deferredAction = 'edit';
    this.id = this.profile.id || '';
    this.data = {
      Name: this.profileName,
      Pin: this.pin,
      'Restriction.Id': this.restrictionId,
    };

    try {
      await actions.profile.update(this.$store, this.id, { data: this.data });
      this.$emit('changeName', this.profileName);
      actions.common.showProfileNotification(this.$store, this.profileEditedText);
      await actions.profile.getAccount(this.$store);
      await this.closeModal();
      this.gaEvent({ category: 'personal_area', action: 'Редактирование профиля' });
    } catch (err) {
      this.handleError(err);
    }
  }

  async addProfile() {
    this.deferredAction = 'add';
    this.data = {
      Name: this.changedName,
      Pin: this.isPinRequired ? this.pin : '',
      'Restriction.Id': this.restrictionId,
    };
    try {
      await api.profile.create({ data: this.data });
      actions.common.showProfileNotification(this.$store, this.profileCreatedText);
      await actions.profile.getAccount(this.$store);
      await this.closeModal();
      this.gaEvent({ category: 'personal_area', action: 'Добавление профиля' });
    } catch (err) {
      this.handleError(err);
    }
  }

  handleError(err: { status: number }) {
    log.error(err);
    if (err.status === HTTP_CODES.FORBIDDEN) {
      this.managing = true;
    }
  }

  async closeModal() {
    actions.common.hideModal(this.$store);
  }

  toggleMode() {
    this.smsMode = !this.smsMode;
  }
}
</script>

<style lang="scss" scoped>
.select-restrictions {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
}
</style>
