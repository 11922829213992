<template>
  <form class="profile-forms" @submit.prevent="">
    <h3 class="mb-32 mt-16" v-html="getTranslation('card_managing')" />
    <div v-if="paymentCard" class="card flex-center-vertical mb-24">
      <img class="card-img" :alt="paymentCardType" :src="paymentCardTypeImage" />
      *<span v-text="paymentCardLast4" />
    </div>
    <div class="profile-forms-description" v-html="getTranslation('card_unbind_warning')" />

    <div class="buttons-wrapper fluid">
      <ButtonDefault
        @click="
          closeModal();
          gaEvent({
            category: 'personal_area',
            action: 'Клик по “Продолжить смотреть” в модальном окне “Управление платежами”',
          });
        "
      >
        <span v-html="getTranslation('continue_watching')" />
      </ButtonDefault>
      <ButtonDefault view="secondary" @click="removePayment()">
        <span v-html="getTranslation('unbind')" />
      </ButtonDefault>
    </div>
  </form>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconAngleLeft from 'src/svg/angle-left.svg';
import IconSVG from 'src/components/IconSVG.vue';
import logger from 'src/utils/logger';

const log = logger('profile');

@Component({
  components: { ButtonDefault, IconSVG },
})
export default class PaymentChange extends SequoiaComponent {
  IconAngleLeft = IconAngleLeft;

  cardLast4 = this.paymentCardLast4;

  get paymentRemovedText() {
    return this.getTranslation('payment_removed').replace(`%card%`, `*${this.cardLast4}`);
  }

  get paymentCard() {
    return selectors.payment.cardSelector(this.$store);
  }

  get paymentCardType() {
    return selectors.payment.cardTypeSelector(this.$store);
  }

  get paymentCardTypeImage() {
    return selectors.payment.cardTypeImageSelector(this.$store);
  }

  get paymentCardLast4() {
    return selectors.payment.cardLast4Selector(this.$store);
  }

  async removePayment() {
    this.gaEvent({
      category: 'personal_area',
      action: 'Клик по “Отвязать” в модальном окне “Управление платежами”',
    });
    try {
      await actions.payment.removePaymentMethod(this.$store);
      await actions.payment.loadPaymentSubscriptions(this.$store);
      actions.common.showProfileNotification(this.$store, this.paymentRemovedText);
      actions.common.hideModal(this.$store);
    } catch (err) {
      log.error(err);
    }
  }

  async closeModal() {
    await actions.profile.getAccount(this.$store);
    actions.common.hideModal(this.$store);
  }
}
</script>
