<template>
  <div class="profiles-list">
    <PersonalTable data-cy="profiles">
      <PersonalTableRow
        v-for="profile in profiles"
        :key="profile.id"
        :active="isProfileActive(profile)"
        :name="profile.name"
      >
        <ProfileActions
          :hide-profile-edit="personalSettings.hideProfileEdit"
          :disable-select="isProfileActive(profile)"
          :disable-delete="isDeleteDisabled(profile)"
          @select="$emit('select', profile)"
          @edit="$emit('edit', profile)"
          @delete="$emit('delete', profile)"
        />
      </PersonalTableRow>
      <PersonalTableRow v-if="!personalSettings.hideProfileEdit" :no-padding="true">
        <ProfileAddButton @click="$emit('create')" />
      </PersonalTableRow>
    </PersonalTable>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import IconSVG from 'src/components/IconSVG.vue';

import { selectors } from 'src/store/selectors';
import PersonalTable from 'src/components/personal/PersonalTable.vue';
import PersonalTableRow from 'src/components/personal/PersonalTableRow.vue';
import { TProfile } from 'src/api/auth/types';
import ProfileActions from 'src/components/personal/profiles-list/parts/ProfileActions.vue';
import ProfileAddButton from 'src/components/personal/profiles-list/parts/ProfileAddButton.vue';

@Component({
  components: {
    ProfileAddButton,
    ProfileActions,
    PersonalTableRow,
    PersonalTable,
    IconSVG,
  },
})
export default class ProfilesList extends SequoiaComponent {
  get personalSettings() {
    return selectors.personal.personalSettingsSelector(this.$store);
  }

  get profiles() {
    return selectors.account.profilesSelector(this.$store);
  }

  isProfileActive(profile: TProfile) {
    const profileId = this.$store.account?.profile?.id || '';
    return !!(profileId && profile.id === profileId);
  }

  isDeleteDisabled(profile: TProfile) {
    return profile.is_master || this.profiles.length <= 1 || this.isProfileActive(profile);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.profiles-list {
  // --------------------------------------------
  // Theme Colors
  // --------------------------------------------
  .light {
    .settings-mob-block {
      background-color: var(--c-light-150);
    }
  }

  .dark {
    .settings-mob-block {
      background-color: var(--c-dark-150);
    }
  }
}
</style>
