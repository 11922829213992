<template>
  <div class="profile-forms">
    <h3 class="mb-32" v-html="getTranslation('subscriptions_managing')" />
    <h5 v-html="getTranslation('subscriptions')" />
    <ul class="subs">
      <li
        v-for="offer in offers"
        :key="offer.id"
        class="sub flex-center-vertical hidden-link-container"
      >
        <div>
          <div v-html="offer.renderingData.title" />
          <span
            v-if="!isYakassaSubscriptionActive(offer.id)"
            class="subscriptions-autopay-none body2 color-error"
          >
            <span
              v-if="isYakassaSubscriptionInRetry(offer.id)"
              v-html="getTranslation('subscription_in_retry_error')"
            />
            <span
              v-else-if="
                (offer.autoRenewInfo && !offer.autoRenewInfo.isAutoRenewEnabled) || offer.yakassa
              "
              class="mb-8"
              v-html="
                getTranslation('autopay_active_till') +
                (offer.yakassa
                  ? getYakassaSubscriptionExpiresAt(offer.id)
                  : getCurrentPeriodExpiresAt(offer))
              "
            />
          </span>
        </div>
        <button
          v-if="
            (offer.autoRenewInfo && offer.autoRenewInfo.isAutoRenewEnabled) ||
            (offer.yakassa &&
              (isYakassaSubscriptionActive(offer.id) || isYakassaSubscriptionInRetry(offer.id)))
          "
          type="button"
          class="link nav-colored hidden-link"
          @click="changeSubscription(offer)"
          v-html="getTranslation('managing')"
        />
        <button
          v-else-if="
            (offer.autoRenewInfo && offer.autoRenewInfo.canResumeAutoRenew) || offer.yakassa
          "
          type="button"
          class="link action-colored"
          @click="enableAutoRenew(offer)"
        >
          {{ getTranslation('renew') }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import { Prop } from 'vue-property-decorator';
import { TOfferEnhancedV2 } from 'src/api/offers/types';
import * as api from 'src/api';
import logger from 'src/utils/logger';
import { subscriptionsSelector } from 'src/store/payment/selectors';
import { expireDate } from 'src/utils/time/date';

const log = logger('personal-subscription-managing');

@Component
export default class SubscriptionManagingV2 extends SequoiaComponent {
  @Prop()
  offers!: Array<TOfferEnhancedV2>;

  get yakassaSubscriptions() {
    return subscriptionsSelector(this.$store);
  }

  yakassaSubscription(id: string) {
    return this.yakassaSubscriptions.find((sub) => sub.id === id);
  }
  yakassaSubscriptionStatus(id: string) {
    return this.yakassaSubscription(id)?.subscription_renew_status || '';
  }

  isYakassaSubscriptionActive(id: string) {
    return this.yakassaSubscriptionStatus(id) === 'active';
  }

  isYakassaSubscriptionInRetry(id: string) {
    return this.yakassaSubscriptionStatus(id) === 'in_retry';
  }

  getYakassaSubscriptionExpiresAt(id: string) {
    return expireDate(this.yakassaSubscription(id)?.expires_at);
  }

  getCurrentPeriodExpiresAt(offer: TOfferEnhancedV2) {
    return expireDate(Date.parse(offer.autoRenewInfo?.currentPeriodExpiresAt || '') / 1000);
  }
  changeSubscription(offer: TOfferEnhancedV2) {
    this.$emit('showSubscriptionProlonging');
    this.$emit('setCurrentSub', offer);
  }

  async enableAutoRenew(offer: TOfferEnhancedV2) {
    if (offer.yakassa) {
      const currentSubId = this.yakassaSubscription(offer.id)?.id || '';
      try {
        await api.profile.activateSubscription(currentSubId);
        await actions.payment.loadPaymentSubscriptions(this.$store);
        await this.$emit('getOffers');
        actions.common.showProfileNotification(
          this.$store,
          this.getTranslation('subscription_renewed').replace(
            `%offerTitle%`,
            offer.renderingData.title || ''
          )
        );
        this.closeModal();
      } catch (err) {
        log.error(err);
      }
    } else {
      try {
        await api.profile.enableAutoRenewSubscription(offer.id || '');
        await actions.payment.loadPaymentSubscriptionsV2(this.$store);
        await this.$emit('getOffers');
        actions.common.showProfileNotification(
          this.$store,
          this.getTranslation('enable_auto_renew').replace(
            `%subscription%`,
            offer.renderingData.title || ''
          )
        );
        this.closeModal();
      } catch (err) {
        log.error(err);
      }
    }

    this.gaEvent({
      category: 'personal_area',
      action: 'Клик по "Возобновить автопродление" в модальном окне "Управление подписками"',
      offer_id: offer.id,
    });
  }

  closeModal() {
    actions.common.hideModal(this.$store);
  }
}
</script>

<style lang="scss" scoped>
.profile-forms {
  h5 {
    margin-bottom: 4px;
  }
}

.subs {
  .sub {
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
}

// --------------------------------------------
// Theme Colors
// --------------------------------------------
.light {
  .subs {
    .sub {
      border-bottom-color: var(--alpha-light-3);
    }
  }
}

.dark {
  .subs {
    .sub {
      border-bottom-color: var(--alpha-dark-3);
    }
  }
}
</style>
