<template>
  <form
    class="profile-forms"
    data-cy="modal-change-password"
    @submit.prevent="handleChangePassword"
  >
    <h3 v-html="getTranslation('change_password')" />

    <div class="inputs">
      <InputText
        v-model="changePasswordForm.oldPassword"
        input-category="floating"
        size="medium"
        icon="visibility"
        data-cy="change-password-old-password"
        :tab-index-for-button="-1"
        :status="errors.oldPassword ? 'error' : undefined"
        :label="getTranslation('current_password')"
        :message="errors.oldPassword"
      />

      <InputText
        v-model="changePasswordForm.newPassword"
        input-category="floating"
        size="medium"
        icon="visibility"
        data-type="new-password"
        data-cy="change-password-new-password"
        :tab-index-for-button="-1"
        :minlength="passwordMinLength"
        :maxlength="passwordMaxLength"
        :status="errors.newPassword ? 'error' : undefined"
        :label="getTranslation('new_password')"
        :message="errors.newPassword"
        :description="getTranslation('password_min_symbols_warning')"
      />

      <InputText
        v-model="changePasswordForm.newPasswordRepeated"
        input-category="floating"
        size="medium"
        icon="visibility"
        data-type="new-password-repeated"
        data-cy="change-password-new-password-repeated"
        :tab-index-for-button="-1"
        :minlength="passwordMinLength"
        :maxlength="passwordMaxLength"
        :status="errors.newPasswordRepeated ? 'error' : undefined"
        :label="getTranslation('repeat_new_password')"
        :message="errors.newPasswordRepeated"
      />
    </div>

    <div class="buttons-wrapper">
      <ButtonDefault data-cy="change-password-submit" :disabled="isSubmitPasswordButtonDisabled">
        <span v-html="getTranslation('change_password')" />
      </ButtonDefault>
      <ButtonDefault
        data-cy="change-password-cancel"
        view="secondary"
        type="button"
        @click.stop.prevent="closeModal"
      >
        <span v-html="getTranslation('cancel')" />
      </ButtonDefault>
    </div>
  </form>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import * as api from 'src/api';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import InputText from 'src/components/ui/input/InputText.vue';

@Component({
  components: {
    InputText,
    ButtonDefault,
  },
})
export default class ChangePassword extends SequoiaComponent {
  @Prop()
  profile: any;

  @Prop()
  content: any;

  showPassErrOld = false;
  showPassErrNew = false;
  showPassErrRepeat = false;

  errors = {
    oldPassword: '',
    newPassword: '',
    newPasswordRepeated: '',
  };
  changePasswordForm = {
    oldPassword: '',
    newPassword: '',
    newPasswordRepeated: '',
  };

  get passwordMinLength() {
    return selectors.common.authAndRegPasswordMinSelector(this.$store);
  }

  get passwordMaxLength() {
    return selectors.common.authAndRegPasswordMaxSelector(this.$store);
  }

  get filteredProfiles() {
    return this.$store.account?.profiles?.filter(
      (profile) => profile.id !== this.$store.account?.profile?.id
    );
  }

  get isSubmitPasswordButtonDisabled() {
    return (
      this.changePasswordForm.newPassword?.length < this.passwordMinLength ||
      this.changePasswordForm.newPasswordRepeated?.length < this.passwordMinLength
    );
  }

  async handleChangePassword() {
    this.showPassErrOld = false;

    try {
      await api.profile.changePassword({
        data: {
          /* eslint-disable babel/camelcase */
          old_password: this.changePasswordForm.oldPassword,
          new_password: this.changePasswordForm.newPassword,
          new_password_repeated: this.changePasswordForm.newPasswordRepeated,
          /* eslint-enable babel/camelcase */
        },
      });
      actions.common.showProfileNotification(
        this.$store,
        this.getTranslation('change_password_success')
      );
      await this.closeModal();
    } catch (err) {
      this.renderError(err);
    }

    this.gaEvent({
      category: 'personal_area',
      action: 'Отправка формы смены пароля',
    });
  }

  // TODO add proper type
  renderError({ info }: any) {
    if (typeof info !== 'undefined') {
      for (const err of info?.data?.errors) {
        switch (err.name) {
          case 'old_password':
            this.errors.oldPassword = err.msg;
            this.showPassErrOld = true;
            break;
          case 'new_password':
            this.errors.newPassword = err.msg;
            this.showPassErrNew = true;
            break;
          case 'new_password_repeated':
            this.errors.newPasswordRepeated = err.msg;
            this.showPassErrRepeat = true;
            break;
        }
      }
    }
  }

  async closeModal() {
    await actions.profile.getAccount(this.$store);
    actions.common.hideModal(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.inputs {
  .floating {
    margin-bottom: 16px;
  }
}
</style>
