<template>
  <div class="profile-modal">
    <h3 class="mb-12" v-html="getTranslation('profiles')" />
    <div
      class="mb-24 body1 color-tertiary"
      v-html="getTranslation('choose_profile_for_settings')"
    />

    <ProfilesList @create="onCreate" @select="onSelect" @edit="onEdit" @delete="onDelete" />
    <div
      v-if="profileContentBlock && !isEmpty(profileContentBlock)"
      class="content-block"
      v-html="getByLanguage(profileContentBlock)"
    />

    <ModalSequoia v-if="isModalOpen && actionName" type="fixed" size="small" min-height="400">
      <ProfileChange
        v-if="actionName === PROFILE_ACTIONS.CHANGE"
        class="with-side-and-bottom-padding"
        :action-name="PROFILE_ACTIONS.CHANGE"
        :profile="currentProfile"
      />
      <ProfileDelete
        v-else-if="actionName === PROFILE_ACTIONS.DELETE"
        class="with-side-and-bottom-padding"
        :action-name="PROFILE_ACTIONS.DELETE"
        :id-to-remove="idToRemove"
        :profile="currentProfile"
      />
      <ProfileEdit
        v-else-if="actionName === PROFILE_ACTIONS.EDIT || actionName === PROFILE_ACTIONS.ADD"
        class="with-side-and-bottom-padding"
        :action-name="actionName"
        :profile="actionName === PROFILE_ACTIONS.EDIT ? currentProfile : profile"
        @changeName="changeProfileName"
      />
    </ModalSequoia>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import { PROFILE_ACTIONS } from 'src/constants';
import ProfileChange from 'src/components/personal/forms/ProfileChange.vue';
import ProfileDelete from 'src/components/personal/forms/ProfileDelete.vue';
import ProfileEdit from 'src/components/personal/forms/ProfileEdit.vue';
import { clickOutside } from 'src/utils/directives';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import { SequoiaComponent } from 'src/mixins';
import ProfilesList from 'src/components/personal/profiles-list/ProfilesList.vue';
import { TProfile } from 'src/api/auth/types';
import NotificationWithDetails from 'src/components/ui/notifications/NotificationWithDetails.vue';
import isEmpty from 'lodash/isEmpty';
import logger from 'src/utils/logger';

const log = logger('profiles-page');

@Component({
  components: {
    ProfilesList,
    ProfileChange,
    ProfileDelete,
    ProfileEdit,
    ModalSequoia,
    NotificationWithDetails,
  },
  directives: { clickOutside },
})
export default class ProfilesPage extends SequoiaComponent {
  isEmpty = isEmpty;
  actionName = '';
  idToRemove? = '';
  profile: Partial<TProfile> = {};
  PROFILE_ACTIONS = PROFILE_ACTIONS;
  currentProfile: Partial<TProfile> = {};
  contentData!: any;

  get profileContentBlock() {
    return selectors.personal.personalSettingsSelector(this.$store)?.profileContentBlock;
  }

  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }

  get contentBlock() {
    return this.getByLanguage(this.contentData?.profileContentBlock);
  }

  get currentProfileId() {
    return this.$store.account?.profile?.id || '';
  }

  mounted() {
    if (!this.$store.profileRestrictions.length) {
      actions.profile.getProfileRestrictions(this.$store);
    }
  }

  showModal() {
    actions.common.showModal(this.$store);
  }

  onCreate() {
    this.profile = {
      name: '',
      pin: '',
      pin_required: false,
      restriction: this.$store.profileRestrictions.length
        ? this.$store.profileRestrictions[0]?.id
        : '1',
    };
    this.actionName = PROFILE_ACTIONS.ADD;
    this.showModal();
  }

  onEdit(profile: TProfile) {
    this.currentProfile = profile;
    if (profile.restriction === 'default') {
      this.currentProfile.restriction = '2';
    }
    this.actionName = PROFILE_ACTIONS.EDIT;
    this.showModal();
  }

  async onSelect(profile: TProfile) {
    this.currentProfile = profile;
    if (this.currentProfileId && profile.id === this.currentProfileId) {
      return;
    }
    if (profile.pin_required) {
      this.actionName = PROFILE_ACTIONS.CHANGE;
      this.showModal();
    } else {
      try {
        await actions.profile.changeProfileWithoutPin(this.$store, profile);

        actions.common.showProfileNotification(
          this.$store,
          `<span>${this.getTranslation('profile_entered')}</span><span>«${profile.name}»</span>`,
          'to-profile'
        );
      } catch (err) {
        log.error(err);
      }
    }

    this.gaEvent({
      category: 'personal_area',
      action: 'Переключение профиля',
      profile: profile.name,
    });
  }

  onDelete(profile: TProfile) {
    this.currentProfile = profile;
    if (profile.is_master || (this.currentProfileId && profile.id === this.currentProfileId)) {
      return;
    }
    this.idToRemove = profile.id;
    this.actionName = PROFILE_ACTIONS.DELETE;
    this.showModal();
  }

  // TODO: do we still need this?
  // onNotAllowToManage() {
  //   this.actionName = PROFILE_ACTIONS.MANAGING;
  //   this.showModal();
  // }

  changeProfileName(name: string) {
    this.currentProfile.name = name;
  }
}
</script>

<style lang="scss" scoped>
.profile-modal {
  &::v-deep .modal .dialog-wrapper .dialog {
    overflow: visible !important;
  }
}
</style>
