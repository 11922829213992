<template>
  <CmsLayout class="personal-page">
    <main class="personal-area container-1440 with-side-padding">
      <h1 v-html="getTranslation('personal_area')" />
      <div class="user-page-container">
        <div class="content-col profile-wrapper">
          <LoaderSpinner v-if="loading" class="loader" />
          <router-view v-else data-cy="profile-wrapper" />
        </div>
        <div class="side-nav side-col" data-cy="profile-nav">
          <div class="scroll-wrap">
            <div class="nav">
              <router-link
                v-for="item in menu"
                :key="item.title"
                :data-cy="item.dataCy"
                :to="item.to"
                class="item"
                @click.native="gaEvent({ category: 'personal_area', action: item.gaEvent })"
                v-html="item.title"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  </CmsLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import { selectors } from 'src/store/selectors';
import CmsLayout from 'src/layouts/CmsLayout.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import logger from 'src/utils/logger';
import { SequoiaPage } from 'src/mixins';
import {
  hasActiveSubscriptionsSelector,
  subscriptionsSelectorV2,
} from 'src/store/payment/selectors';

const log = logger('personal');

@Component({
  components: {
    CmsLayout,
    LoaderSpinner,
  },
})
export default class PersonalPage extends SequoiaPage {
  loading = true;
  localStorageLastItemKey = 'userMenuLastItem';

  @Watch('$route.path')
  onRoutePathChange() {
    localStorage?.setItem(this.localStorageLastItemKey, this.$route.path);
  }

  get settings() {
    return selectors.personal.personalSettingsSelector(this.$store);
  }

  get hasActiveSubscriptions() {
    return selectors.payment.hasActiveSubscriptionsSelector(this.$store);
  }

  get menu() {
    const menu = [];

    if (this.hasActiveSubscriptions) {
      menu.push({
        title: this.getTranslation('subscriptions'),
        to: '/personal/subscriptions',
        dataCy: 'item-subscriptions',
        gaEvent: `Клик по вкладке "${this.getTranslation('subscriptions')}"`,
      });
    }
    if (!this.settings?.hidePersonalData) {
      menu.push({
        title: this.getTranslation('personal_data'),
        to: '/personal/data',
        dataCy: 'item-personal-data',
        gaEvent: `Клик по вкладке "${this.getTranslation('personal_data')}"`,
      });
    }
    if (!this.settings?.hideProfileManagement) {
      menu.push({
        title: this.getTranslation('profile_management'),
        to: '/personal/profiles',
        dataCy: 'item-management',
        gaEvent: `Клик по вкладке "${this.getTranslation('profile_management')}"`,
      });
    }
    return menu;
  }

  get redirectMenuItem() {
    const lastItemTo = localStorage?.getItem(this.localStorageLastItemKey);
    const menuItem = this.menu.find((item) => item.to === lastItemTo);
    if (menuItem && lastItemTo) {
      return lastItemTo;
    }
    return this.menu.length ? this.menu[0].to : '';
  }

  getMetaInfo() {
    return {
      title: this.getTranslation('profiles_meta_title'),
      meta: [],
    };
  }

  async mounted() {
    if (this.isAnonymous) {
      return this.$router.push('/login');
    }

    if (this.$route.path.split('/').filter((part) => part).length === 1) {
      // redirect from /user -> /user/something
      const to = this.redirectMenuItem;
      if (to) {
        await this.$router.push(to);
      }
    }

    actions.common.showFooter(this.$store);

    try {
      await actions.payment.loadPaymentSubscriptions(this.$store);
    } catch (err) {
      log.error('onLoadPaymentSubscriptions', err);
    }

    try {
      await actions.payment.loadPaymentSubscriptionsV2(this.$store);
    } catch (err) {
      log.error('onLoadPaymentSubscriptionsV2', err);
    }

    this.loading = false;
  }

  destroyed() {
    actions.common.hideFooter(this.$store);
  }
}
</script>

<style lang="scss">
@import 'src/styles/common/side-nav';
</style>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.personal-area {
  flex: 1;
  margin-top: 64px;
  line-height: 1.5rem;

  @include tablet {
    margin-top: 48px;
  }

  @include mobile {
    margin-top: 32px;
  }

  h1 {
    margin: 0 0 3rem;

    @include mobile-s {
      margin: 0 0 2rem;
    }
  }

  .user-page-container {
    display: flex;

    @include mobile-and-tablet {
      flex-direction: column-reverse;
    }

    .content-col {
      margin-right: 48px;

      @include desktop-s {
        margin-right: 40px;
      }

      @include mobile-and-tablet {
        max-width: 672px;
        margin-right: 0;
      }

      @include mobile-s {
        max-width: 320px;
      }
    }

    .side-col {
      min-width: 230px;
    }
  }
}
</style>
