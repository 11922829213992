<template>
  <div class="profile-forms">
    <div v-if="managing && withSmsConfirmation && smsMode">
      <h3 v-html="getTranslation('edit_profile')" />
      <div class="profile-forms-description" v-html="getTranslation('sms_sent')" />

      <ProfileSmsAuth class="mb-16" @authorized="onAuthConfirmed" />

      <a
        class="link action-colored"
        @click="toggleMode"
        v-html="getTranslation('enter_password')"
      />
    </div>

    <div v-else-if="managing">
      <h3 v-html="getTranslation('edit_profile')" />
      <div class="profile-forms-description" v-html="getTranslation('confirm_text')" />

      <ProfileManaging @authorized="onAuthConfirmed" />
    </div>

    <form v-else class="edit-form" @submit.prevent="deleteProfile">
      <h3 v-html="getTranslation('profile_delete')" />
      <div class="profile-forms-description" v-html="deleteConfirmation" />

      <div class="buttons-wrapper">
        <ButtonDefault view="danger">
          <span v-html="getTranslation('profile_delete')" />
        </ButtonDefault>

        <ButtonDefault view="secondary" type="button" @click="closeModal">
          <span v-html="getTranslation('cancel')" />
        </ButtonDefault>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { actions } from 'src/store/actions';
import * as api from 'src/api';
import { HTTP_CODES } from 'src/constants';
import InputText from 'src/components/ui/input/InputText.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ProfileSmsAuth from 'src/components/personal/forms/ProfileSmsAuth.vue';
import { TProfile } from 'src/api/auth/types';
import ProfileManaging from 'src/components/personal/forms/ProfileManaging.vue';
import { wlDetector } from 'src/utils';

@Component({
  components: { ProfileManaging, ProfileSmsAuth, InputText, ButtonDefault },
})
export default class ProfileDelete extends SequoiaComponent {
  managing = false;
  smsMode = true;

  @Prop()
  idToRemove!: string;

  @Prop()
  profile!: Partial<TProfile>;

  @Prop()
  actionName!: string;

  get deleteConfirmation() {
    const profileInsert = '<span class="bold name-overflow">' + this.profile.name + '</span>';
    const deleteConfirm = this.getTranslation('profile_delete_warning');
    return deleteConfirm.replace('%profile%', profileInsert);
  }

  get withSmsConfirmation() {
    return wlDetector().isKt || wlDetector().isMegafon || wlDetector().isIntersv;
  }

  get profileDeletedText() {
    const profileDeletedTranslation = this.getTranslation('profile_deleted');
    const profileDeleted = profileDeletedTranslation.replace(
      '%profile%',
      `<span>«${this.profile.name}»</span>`
    );
    return `<span>${profileDeleted}</span>`;
  }

  toggleMode() {
    this.smsMode = !this.smsMode;
  }

  async deleteProfile() {
    try {
      await api.profile.remove(this.idToRemove);
      await actions.profile.getAccount(this.$store);

      actions.common.showProfileNotification(this.$store, this.profileDeletedText);
      this.closeModal();
    } catch (err) {
      this.handleError(err);
    }

    this.gaEvent({ category: 'personal_area', action: 'Удаление профиля' });
  }

  closeModal() {
    actions.common.hideModal(this.$store);
  }

  handleError(err: { status: number }) {
    if (err.status === HTTP_CODES.FORBIDDEN) {
      this.managing = true;
    }
  }

  async onAuthConfirmed() {
    try {
      await this.deleteProfile();
    } catch (err) {
      this.handleError(err);
    }
  }
}
</script>
