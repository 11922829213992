<template>
  <form class="profile-forms" @submit.prevent="">
    <h3 class="mb-24 mt-16" v-html="currentOffer.preview.title" />
    <div class="profile-forms-description" v-html="autopayCancelWarning" />

    <div class="buttons-wrapper fluid">
      <ButtonDefault
        @click="
          closeModal();
          gaEvent({
            category: 'personal_area',
            action: 'Клик по “Продолжить смотреть” в модальном окне “Управление подписками”',
            offer_id: currentPayment.id,
          });
        "
      >
        <span v-html="getTranslation('continue_watching')" />
      </ButtonDefault>
      <ButtonDefault view="secondary" @click="cancelSub()">
        <span v-html="getTranslation('disconnect')" />
      </ButtonDefault>
    </div>
  </form>
</template>

<script lang="ts">
import { actions } from 'src/store/actions';
import * as api from 'src/api';
import { DateTime } from 'src/utils/time/date-time';
import { THRESHOLDS } from 'src/constants';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconAngleLeft from 'src/svg/angle-left.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({ components: { ButtonDefault, IconSVG } })
export default class SubscriptionProlonging extends SequoiaComponent {
  IconAngleLeft = IconAngleLeft;

  @Prop()
  currentOffer!: any;

  @Prop()
  currentPayment: any;

  get autopayCancelWarning() {
    const warning = this.getTranslation('autopay_cancel_warning');
    return warning.replace(`%expire%`, this.expireDate || '');
  }

  get autopayCancelledText() {
    const cancelled = this.getTranslation('autopay_cancelled');
    return cancelled.replace(`%subscribe%`, `${this.currentOffer?.preview.title}` || '');
  }

  get expireDate() {
    const { expires_at } = this.currentPayment;
    const { dateUTCMultiplier } = THRESHOLDS.subscribes;
    const date = new Date(expires_at * dateUTCMultiplier);
    const month = DateTime.getDMonth(date);
    const year = date.getFullYear();
    return `${month}.${year}`;
  }

  async cancelSub() {
    try {
      await api.profile.cancelSubscription(this.currentPayment.id);
      await actions.payment.loadPaymentSubscriptions(this.$store);
      actions.common.showProfileNotification(this.$store, this.autopayCancelledText);
    } catch (err) {
      this.$emit('showNotificationError', this.getTranslation('subscription_renew_in_progress'));
    }
    this.closeModal();
    this.gaEvent({
      category: 'personal_area',
      action: 'Клик по "Отключить" в модальном окне "Управление подписками"',
      offer_id: this.currentPayment.id,
    });
  }

  closeModal() {
    actions.common.hideModal(this.$store);
  }
}
</script>
