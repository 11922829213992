<template>
  <div class="profile-forms">
    <h3 class="mb-32" v-html="getTranslation('subscriptions_managing')" />
    <h5 v-html="getTranslation('subscriptions')" />
    <ul class="subs">
      <li
        v-for="offer in offers"
        :key="offer.preview.id"
        class="sub flex-center-vertical hidden-link-container"
      >
        <div>
          <div v-html="offer.preview.title" />
          <span
            v-if="!isCurrentSubscriptionActive(offer.preview.id)"
            class="subscriptions-autopay-none body2 color-error"
          >
            <span
              v-if="isCurrentSubscriptionInRetry(offer.preview.id)"
              v-html="getTranslation('subscription_in_retry_error')"
            />
            <span v-else>
              <span v-html="getTranslation('autopay_active_till')" />
              <span v-text="expireDate(currentSubscription(offer.preview.id).expires_at)" />
            </span>
          </span>
        </div>

        <button
          v-if="['active', 'in_retry'].includes(currentSubscriptionStatus(offer.preview.id))"
          type="button"
          class="link nav-colored hidden-link"
          @click="changeSubscription(offer)"
          v-html="getTranslation('managing')"
        />
        <button
          v-else
          type="button"
          class="link action-colored"
          @click="renewPayment(offer.preview)"
          v-html="getTranslation('renew')"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { actions } from 'src/store/actions';
import * as api from 'src/api';
import { DateTime } from 'src/utils/time/date-time';
import { THRESHOLDS } from 'src/constants';
import logger from 'src/utils/logger';
import { Prop } from 'vue-property-decorator';

const log = logger('profile');

@Component
export default class SubscriptionManaging extends SequoiaComponent {
  @Prop()
  offers!: Array<any>; // TODO add proper type

  currentSubscription(id?: string) {
    return this.$store.payment.subscriptions.find((item) => item.offer.id === id);
  }

  currentSubscriptionStatus(offerPreviewId: string): any {
    return this.currentSubscription(offerPreviewId)?.subscription_renew_status || '';
  }

  isCurrentSubscriptionActive(offerPreviewId: string) {
    return this.currentSubscriptionStatus(offerPreviewId) === 'active';
  }

  isCurrentSubscriptionInRetry(offerPreviewId: string) {
    return this.currentSubscriptionStatus(offerPreviewId) === 'in_retry';
  }

  expireDate(expire: number) {
    return `${DateTime.getDMonth(
      new Date(expire * THRESHOLDS.subscribes.dateUTCMultiplier)
    )}.${new Date(expire * THRESHOLDS.subscribes.dateUTCMultiplier).getFullYear()}`;
  }

  changeSubscription(offer: { preview: { id: string | undefined } }) {
    this.$emit('showSubscriptionProlonging');
    this.$emit('setCurrentSub', offer);
    this.gaEvent({
      category: 'personal_area',
      action: 'Клик по "Управление" в модальном окне "Управление подписками"',
      offer_id: offer.preview.id,
    });
  }

  closeModal() {
    actions.common.hideModal(this.$store);
  }

  async renewPayment(offer: { id: string | undefined; title: any }) {
    const currentSubId = this.currentSubscription(offer.id)?.id || '';
    try {
      await api.profile.activateSubscription(currentSubId);
      await actions.payment.loadPaymentSubscriptions(this.$store);
      const subscriptionRenewedText = this.getTranslation('subscription_renewed').replace(
        `%offerTitle%`,
        offer.title || ''
      );
      actions.common.showProfileNotification(this.$store, subscriptionRenewedText);
      this.closeModal();
    } catch (err) {
      log.error(err);
    }
    this.gaEvent({
      category: 'personal_area',
      action: 'Клик по "Возобновить автопродление" в модальном окне "Управление подписками"',
      offer_id: offer.id,
    });
  }
}
</script>

<style lang="scss" scoped>
.profile-forms {
  h5 {
    margin-bottom: 4px;
  }
}

.subs {
  .sub {
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
}

// --------------------------------------------
// Theme Colors
// --------------------------------------------
.light {
  .subs {
    .sub {
      border-bottom-color: var(--alpha-light-3);
    }
  }
}

.dark {
  .subs {
    .sub {
      border-bottom-color: var(--alpha-dark-3);
    }
  }
}
</style>
