<template>
  <div v-if="offer.preview" class="subscriptions-block block block-bg dark">
    <h4 class="mb-24" v-html="offer.preview.title" />

    <div v-if="!isCurrentSubscriptionActive" class="subscriptions-autopay mb-24">
      <div class="color-error">
        <span
          v-if="isCurrentSubscriptionInRetry"
          v-html="getTranslation('subscription_in_retry_error')"
        />
        <span v-else>
          <span v-html="getTranslation('autopay_canceled_till')" />&#32;<span v-html="updatedAt" />.
        </span>
      </div>
      <PaymentRetryActions
        v-if="isCurrentSubscriptionInRetry"
        :subscription-option-id="currentSubscription.id"
        @loading="onLoadingChange"
        @success="onSuccess"
      />
      <ButtonDefault v-else @click="renewPayment(currentSubscription)">
        <span v-html="getTranslation('autopay_renew')" />
      </ButtonDefault>
      <LoaderSpinner v-if="isLoading" />
    </div>

    <ul class="subscriptions-info mb-16">
      <li v-if="currentSubscription.offer.price">
        <span v-html="getTranslation('payment')" />:&#32;
        <span v-text="currentSubscription.offer.price" /> ₽/<span v-html="paymentPeriod" />
      </li>
      <li v-if="expiresAt">
        <span v-html="getTranslation(nextPaymentText)" />:&#32;
        <span v-html="expiresAt" />
      </li>
      <li v-if="offer.showcaseChannelsCount">
        <span v-html="getTranslation('channels_count')" />:&#32;
        <span v-html="offer.showcaseChannelsCount" />
      </li>
    </ul>

    <transition-group
      v-if="channels.length"
      name="fade-ease-out-slow"
      class="subscriptions-channels flex"
    >
      <img
        v-for="channel in channels"
        :key="channel.id"
        :src="getChannelImageUrl(channel)"
        alt=""
      />
    </transition-group>
    <button
      v-if="allChannels.length > channels.length && !showAllChannels"
      type="button"
      class="link action-colored"
      @click="onShowAllChannels"
      v-html="getTranslation('show_all_channels')"
    />
  </div>
</template>

<script lang="ts">
import plurar from 'plural-ru';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import * as api from 'src/api';
import { expireDate } from 'src/utils/time/date';
import { imageServerUrl } from 'src/utils/images';
import logger from 'src/utils/logger';
import { convertToDays } from 'src/utils/time/convert-time';
import { DESKTOP_WINDOW_WIDTH, TABLET_WINDOW_WIDTH } from 'src/constants';
import { actions } from 'src/store/actions';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import PaymentRetryActions from 'src/components/payment-retry/parts/PaymentRetryActions.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import { getChannelLogo, getChannelTitle } from 'src/utils/channel';
import { TChannelEnhanced } from 'src/api/channels/types';

const log = logger('personal-subscription-block');

enum INITIAL_CHANNELS_LENGTH {
  desktop = 27,
  tablet = 24,
  mobile = 12,
}

@Component({
  components: { LoaderSpinner, PaymentRetryActions, ButtonDefault },
})
export default class SubscriptionBlock extends SequoiaComponent {
  isLoading = false;
  showAllChannels = false;

  @Prop({ required: true })
  offer!: {
    channels: Array<any>;
    [key: string]: any; // TODO fix types
    showcaseChannelsCount: number;
  };

  get allChannels() {
    if (!(this.$store.tvChannels?.list && this.offer?.channels)) {
      return [];
    }

    // remove duplicates
    const uniqueChannels = Array.from(new Set(this.offer.channels));

    // map channels
    const channels = uniqueChannels.reduce((accumulator, offerChannelId) => {
      const channel = this.$store.tvChannels.list[offerChannelId];
      if (channel) {
        accumulator.push(channel);
      }
      return accumulator;
    }, []);

    // sort by channel's title
    return channels.sort((a: any, b: any) =>
      this.getChannelTitle(a) > this.getChannelTitle(b) ? 1 : -1
    );
  }

  get channels() {
    return this.allChannels.slice(0, this.channelsLength);
  }

  get channelsLength() {
    if (this.showAllChannels) {
      return this.allChannels.length;
    }

    let deviceType: keyof typeof INITIAL_CHANNELS_LENGTH = 'mobile';
    if (window.innerWidth >= DESKTOP_WINDOW_WIDTH) {
      deviceType = 'desktop';
    } else if (window.innerWidth >= TABLET_WINDOW_WIDTH) {
      deviceType = 'tablet';
    }

    return INITIAL_CHANNELS_LENGTH[deviceType];
  }

  get currentSubscription() {
    return this.$store.payment.subscriptions.find(
      (item) => item.offer.id === this.offer.preview.id
    );
  }

  get subscriptionRenewedText() {
    const renewed = this.getTranslation('subscription_renewed');
    return renewed.replace(`%offerTitle%`, this.offer.preview.title || '');
  }

  get paymentPeriod() {
    const period = Math.round(convertToDays(this.currentSubscription?.offer?.period, 'second'));
    return plurar(
      period,
      '%d ' + this.getTranslation('day'),
      '%d ' + this.getTranslation('day_genitive'),
      '%d ' + this.getTranslation('days_genitive')
    );
  }

  get expiresAt() {
    return expireDate(this.currentSubscription?.expires_at);
  }

  get updatedAt() {
    return expireDate(this.currentSubscription?.expires_at);
  }

  get currentSubscriptionStatus() {
    return this.currentSubscription?.subscription_renew_status || '';
  }

  get isCurrentSubscriptionActive() {
    return this.currentSubscriptionStatus === 'active';
  }

  get isCurrentSubscriptionInRetry() {
    return this.currentSubscriptionStatus === 'in_retry';
  }

  get nextPaymentText() {
    return this.offer.temporaryOffer ? 'next_payment_trial' : 'next_payment';
  }

  async mounted() {
    try {
      await actions.payment.loadPaymentMethod(this.$store);
    } catch (err) {
      log.error(err);
    }
  }

  onShowAllChannels() {
    this.showAllChannels = true;
    this.gaEvent({
      category: 'personal_area',
      action: 'Клик по "Показать все каналы"',
      offer_id: this.currentSubscription?.offer.id,
    });
  }

  getChannelTitle(channel: TChannelEnhanced) {
    return getChannelTitle(channel);
  }

  getChannelLogo(channel: TChannelEnhanced) {
    return getChannelLogo(channel);
  }

  getChannelImageUrl(channel: any) {
    return imageServerUrl(this.getChannelLogo(channel), 168, 96);
  }

  onLoadingChange(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  async onSuccess() {
    await actions.payment.loadPaymentSubscriptions(this.$store);
  }

  async renewPayment(sub: any) {
    try {
      await api.profile.activateSubscription(sub.id);
      await actions.payment.loadPaymentSubscriptions(this.$store);
      actions.common.showProfileNotification(this.$store, this.subscriptionRenewedText);
    } catch (err) {
      log.error(err);
    }
    this.gaEvent({
      category: 'personal_area',
      action: 'Клик по "Возобновить автопродление" на  странице подписки',
      offer_id: sub.offer.id,
    });
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.subscriptions-block {
  margin-bottom: 16px;
  background-image: linear-gradient(
      to bottom,
      rgba(13, 13, 13, 0.8),
      rgba(13, 13, 13, 0.9) 60%,
      #0d0d0d
    ),
    url('/public/images/backgrounds/quick-sub.png');
  border-radius: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  .link {
    margin-top: 16px;
  }

  .subscriptions-info {
    @include body2;

    li {
      position: relative;
      padding-left: 12px;
      margin: 4px 0;

      &::before {
        content: '';
        position: absolute;
        top: 40%;
        left: 0;
        width: 4px;
        height: 4px;
        background-color: var(--c-light-brand);
        border-radius: 50%;
      }
    }
  }

  .subscriptions-channels {
    img {
      width: 5.25rem;
      height: 3rem;
      margin-right: 8px;
      margin-bottom: 8px;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }

  .subscriptions-autopay {
    line-height: 1.25rem;

    a {
      text-decoration: none;
      border-bottom-style: dashed;
      border-bottom-width: 1px;

      @include devices-with-hover {
        &:hover {
          border-bottom-style: solid;
        }
      }
    }

    .color-error {
      margin-bottom: 12px;
    }

    @include mobile-and-tablet {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        margin-top: 16px;
      }
    }
  }
}
</style>
