<template>
  <div>
    <h3 class="mb-24" v-html="getTranslation('active_subscriptions')" />

    <div v-if="currentSubscriptions.length" data-cy="subscriptions">
      <SubscriptionBlockV2
        v-for="offer in currentSubscriptions"
        :key="offer.id"
        :offer="offer"
        @getOffers="getOffers"
      />
    </div>
    <div
      v-else-if="!loading"
      class="subscriptions-none"
      v-html="getTranslation('no_active_subscriptions')"
    />
    <LoaderSpinner v-if="loading" class="loader" />

    <div v-if="canBeManaging" class="subscriptions-managing">
      <button
        type="button"
        class="link action-tertiary"
        @click="openSubModal"
        v-html="getTranslation('subscriptions_managing')"
      />
    </div>

    <ModalSequoia
      v-if="isModalOpen"
      type="fixed"
      size="small"
      :with-button-back="subProlongModal"
      @back="handleModalStepBack"
    >
      <SubscriptionManagingV2
        v-if="subManagingModal"
        class="with-side-and-bottom-padding"
        :offers="currentSubscriptions"
        @showSubscriptionProlonging="showSubscriptionProlonging"
        @setCurrentSub="setCurrentSub"
        @getOffers="getOffers"
      />
      <SubscriptionProlongingV2
        v-else-if="subProlongModal"
        class="with-side-and-bottom-padding"
        :currentOffer="currentOffer"
        @showNotificationError="showNotificationError"
        @getOffers="getOffers"
      />
    </ModalSequoia>

    <div class="notifications-container">
      <NotificationWithDetails
        v-if="notificationError"
        :title="getTranslation('error')"
        :message="notificationError"
        @hide="closeNotificationError"
      />
    </div>
  </div>
</template>

<script lang="ts">
import * as api from 'src/api';
import SubscriptionManaging from 'src/components/personal/forms/SubscriptionManaging.vue';
import SubscriptionManagingV2 from 'src/components/personal/forms/SubscriptionManagingV2.vue';
import SubscriptionProlonging from 'src/components/personal/forms/SubscriptionProlonging.vue';
import SubscriptionProlongingV2 from 'src/components/personal/forms/SubscriptionProlongingV2.vue';
import SubscriptionBlock from 'src/components/personal/SubscriptionBlock.vue';
import SubscriptionBlockV2 from 'src/components/personal/SubscriptionBlockV2.vue';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import NotificationSimple from 'src/components/ui/notifications/NotificationSimple.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import { actions } from 'src/store/actions';
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { selectors } from 'src/store/selectors';
import NotificationWithDetails from 'src/components/ui/notifications/NotificationWithDetails.vue';
import { TOfferEnhancedV2 } from 'src/api/offers/types';
import { SubscriptionInfoV2 } from 'src/models/ts/offer';

@Component({
  components: {
    SubscriptionManaging,
    SubscriptionManagingV2,
    SubscriptionProlonging,
    SubscriptionProlongingV2,
    SubscriptionBlock,
    SubscriptionBlockV2,
    ModalSequoia,
    NotificationSimple,
    NotificationWithDetails,
    LoaderSpinner,
  },
})
export default class SubscriptionsPage extends SequoiaComponent {
  currentOffer: TOfferEnhancedV2 | undefined;
  currentSubscriptions: Array<TOfferEnhancedV2> = [];
  subManagingModal = false;
  subProlongModal = false;
  loading = true;
  notificationError = '';

  async mounted() {
    if (!this.$store.flags.tvDataState.loaded) {
      await actions.tvChannels.loadTvData(this.$store);
    }

    await this.getOffers();
    this.loading = false;
  }

  get canBeManaging() {
    return (
      this.currentSubscriptions.filter(
        (offer) =>
          offer.autoRenewInfo?.isAutoRenewEnabled ||
          offer.autoRenewInfo?.canResumeAutoRenew ||
          offer.yakassa
      ).length > 0
    );
  }

  get isModalOpen() {
    return selectors.common.isModalOpenSelector(this.$store);
  }

  async getOffers() {
    this.currentSubscriptions = [];
    for (const sub of selectors.payment.subscriptionsSelectorV2(this.$store)) {
      await this.prepareOffer(sub);
    }
  }

  showModal() {
    actions.common.showModal(this.$store);
  }

  openSubModal() {
    this.handleModalStepBack();
    this.showModal();
    this.gaEvent({
      category: 'personal_area',
      action: 'Клик по "Управление подписками"',
    });
  }

  setCurrentSub(sub: TOfferEnhancedV2) {
    this.currentOffer = sub;
  }

  showNotificationError(text: string) {
    this.notificationError = text;
  }

  closeNotificationError() {
    this.notificationError = '';
  }

  handleModalStepBack() {
    this.subManagingModal = true;
    this.subProlongModal = false;
  }

  showSubscriptionProlonging() {
    this.subProlongModal = true;
    this.subManagingModal = false;
  }

  async prepareOffer(offer: SubscriptionInfoV2) {
    this.loading = true;
    try {
      const offerEnhanced: TOfferEnhancedV2 = {
        renderingData: JSON.parse(offer.mainOffer?.renderingDataJson || '{}'),
        ...offer,
      };
      if (offer.mainOffer?.showcaseChannelsCount) {
        const { channels } = await api.offers.getOfferChannels(offer.mainOffer.offerId);
        offerEnhanced.channels = channels;
      }
      this.currentSubscriptions.push(offerEnhanced);
    } catch (err) {}
    this.loading = false;
  }
}
</script>
